@import "../../../styles/media.scss";

.homeScrollWidget {
  position: relative;
  width: 100%;
  height: 80vh;
  background-image: url("/images/home-bg-mobile.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include desktop {
    background-image: url("/images/home-bg-desktop.jpg");
    background-attachment: fixed;
  }
}
