.button {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
.show {
  opacity: 1;
  transition: 0.3s all ease-in-out;
}

.hide {
  opacity: 0;
  transition: 0.3s all ease-in-out;
}
