@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin desktopLarge {
  @media (min-width: 1300px) {
    @content;
  }
}

@mixin media-query($from, $to) {
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}
