@import "../../../styles/media";

.homeVenues {
  // h5 is the title of the section
  h5 {
    padding: 1rem;
  }

  .venuesContainer {
    display: grid;
    .venue {
      position: relative;
      overflow: hidden;

      // img directly under the picture element
      img {
        transition: 0.5s;
      }

      .addressContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        opacity: 0;
        transition: 0.5s;
      }

      .dataContainer {
        padding: 1rem 0.2rem;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow-x: scroll;

        .links {
          display: flex;
          align-items: center;
          gap: 0.25rem;

          .link {
            background-color: rgba(107, 107, 107, 0.2);
            padding: 0.25rem 0.5rem;
            border-radius: 0.25rem;
            border: 0.3px solid var(--color-grey);
            align-self: stretch;
            display: flex;
            align-items: center;
            &:hover {
              background-color: #000;
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }

      &:hover {
        img {
          filter: blur(30px);
        }

        .addressContainer {
          opacity: 1;
        }
      }
    }
  }
}

.threeAcross {
  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }
}

.fourAcross {
  @include desktop {
    grid-template-columns: repeat(4, 1fr);
  }
}
